import React, { useState } from 'react';
import axios from 'axios';
import './App.css';
import Spinner from './Spinner';

function App() {
  const [query, setQuery] = useState('');
  const [response, setResponse] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    try {
      const res = await axios.post('http://74.235.95.232:8000/query', { query });
      setResponse(res.data.response);
    } catch (error) {
      console.error("There was an error!", error);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Nyungne Assistant App</h1>
        <div className="more-info">
          <img src="https://images.squarespace-cdn.com/content/v1/61b2dfde7199aa61d7893c55/57bbae8f-9a08-42ea-8c89-17b0f3decf2e/nyungnebkcover.jpg?format=1500w" alt="Nyungne Book Cover" className="book-cover"/>
          <p>For more information about Nyungne, visit <a href="https://www.sercholing.org/" target="_blank" rel="noopener noreferrer">Sercholing</a>.</p>
        </div>
        <form onSubmit={handleSubmit}>
          <textarea
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Enter your query about Nyungne"
            rows="5"
            className="query-input"
          />
          <button type="submit" disabled={isProcessing}>Submit</button>
        </form>
        {isProcessing && <Spinner />}
        {response && <div className="response"><h2>Response:</h2><p>{response}</p></div>}
      </header>
    </div>
  );
}

export default App;
